export default {
    "code[class*=\"language-\"]": {
        "background": "hsl(230, 1%, 98%)",
        "color": "hsl(230, 8%, 24%)",
        "fontFamily": "\"Fira Code\", \"Fira Mono\", Menlo, Consolas, \"DejaVu Sans Mono\", monospace",
        "direction": "ltr",
        "textAlign": "left",
        "whiteSpace": "pre",
        "wordSpacing": "normal",
        "wordBreak": "normal",
        "lineHeight": "1.5",
        "MozTabSize": "2",
        "OTabSize": "2",
        "tabSize": "2",
        "WebkitHyphens": "none",
        "MozHyphens": "none",
        "msHyphens": "none",
        "hyphens": "none"
    },
    "pre[class*=\"language-\"]": {
        "background": "hsl(230, 1%, 98%)",
        "color": "hsl(230, 8%, 24%)",
        "fontFamily": "\"Fira Code\", \"Fira Mono\", Menlo, Consolas, \"DejaVu Sans Mono\", monospace",
        "direction": "ltr",
        "textAlign": "left",
        "whiteSpace": "pre",
        "wordSpacing": "normal",
        "wordBreak": "normal",
        "lineHeight": "1.5",
        "MozTabSize": "2",
        "OTabSize": "2",
        "tabSize": "2",
        "WebkitHyphens": "none",
        "MozHyphens": "none",
        "msHyphens": "none",
        "hyphens": "none",
        "padding": "1em",
        "margin": "0.5em 0",
        "overflow": "auto",
        "borderRadius": "0.3em"
    },
    "code[class*=\"language-\"]::-moz-selection": {
        "background": "hsl(230, 1%, 90%)",
        "color": "inherit"
    },
    "code[class*=\"language-\"] *::-moz-selection": {
        "background": "hsl(230, 1%, 90%)",
        "color": "inherit"
    },
    "pre[class*=\"language-\"] *::-moz-selection": {
        "background": "hsl(230, 1%, 90%)",
        "color": "inherit"
    },
    "code[class*=\"language-\"]::selection": {
        "background": "hsl(230, 1%, 90%)",
        "color": "inherit"
    },
    "code[class*=\"language-\"] *::selection": {
        "background": "hsl(230, 1%, 90%)",
        "color": "inherit"
    },
    "pre[class*=\"language-\"] *::selection": {
        "background": "hsl(230, 1%, 90%)",
        "color": "inherit"
    },
    ":not(pre) > code[class*=\"language-\"]": {
        "padding": "0.2em 0.3em",
        "borderRadius": "0.3em",
        "whiteSpace": "normal"
    },
    "comment": {
        "color": "hsl(230, 4%, 64%)",
        "fontStyle": "italic"
    },
    "prolog": {
        "color": "hsl(230, 4%, 64%)"
    },
    "cdata": {
        "color": "hsl(230, 4%, 64%)"
    },
    "doctype": {
        "color": "hsl(230, 8%, 24%)"
    },
    "punctuation": {
        "color": "hsl(230, 8%, 24%)"
    },
    "entity": {
        "color": "hsl(230, 8%, 24%)",
        "cursor": "help"
    },
    "attr-name": {
        "color": "hsl(35, 99%, 36%)"
    },
    "class-name": {
        "color": "hsl(35, 99%, 36%)"
    },
    "boolean": {
        "color": "hsl(35, 99%, 36%)"
    },
    "constant": {
        "color": "hsl(35, 99%, 36%)"
    },
    "number": {
        "color": "hsl(35, 99%, 36%)"
    },
    "atrule": {
        "color": "hsl(35, 99%, 36%)"
    },
    "keyword": {
        "color": "hsl(301, 63%, 40%)"
    },
    "property": {
        "color": "hsl(5, 74%, 59%)"
    },
    "tag": {
        "color": "hsl(5, 74%, 59%)"
    },
    "symbol": {
        "color": "hsl(5, 74%, 59%)"
    },
    "deleted": {
        "color": "hsl(5, 74%, 59%)"
    },
    "important": {
        "color": "hsl(5, 74%, 59%)"
    },
    "selector": {
        "color": "hsl(119, 34%, 47%)"
    },
    "string": {
        "color": "hsl(119, 34%, 47%)"
    },
    "char": {
        "color": "hsl(119, 34%, 47%)"
    },
    "builtin": {
        "color": "hsl(119, 34%, 47%)"
    },
    "inserted": {
        "color": "hsl(119, 34%, 47%)"
    },
    "regex": {
        "color": "hsl(119, 34%, 47%)"
    },
    "attr-value": {
        "color": "hsl(119, 34%, 47%)"
    },
    "attr-value > .token.punctuation": {
        "color": "hsl(119, 34%, 47%)"
    },
    "variable": {
        "color": "hsl(221, 87%, 60%)"
    },
    "operator": {
        "color": "hsl(221, 87%, 60%)"
    },
    "function": {
        "color": "hsl(221, 87%, 60%)"
    },
    "url": {
        "color": "hsl(198, 99%, 37%)"
    },
    "attr-value > .token.punctuation.attr-equals": {
        "color": "hsl(230, 8%, 24%)"
    },
    "special-attr > .token.attr-value > .token.value.css": {
        "color": "hsl(230, 8%, 24%)"
    },
    ".language-css .token.selector": {
        "color": "hsl(5, 74%, 59%)"
    },
    ".language-css .token.property": {
        "color": "hsl(230, 8%, 24%)"
    },
    ".language-css .token.function": {
        "color": "hsl(198, 99%, 37%)"
    },
    ".language-css .token.url > .token.function": {
        "color": "hsl(198, 99%, 37%)"
    },
    ".language-css .token.url > .token.string.url": {
        "color": "hsl(119, 34%, 47%)"
    },
    ".language-css .token.important": {
        "color": "hsl(301, 63%, 40%)"
    },
    ".language-css .token.atrule .token.rule": {
        "color": "hsl(301, 63%, 40%)"
    },
    ".language-javascript .token.operator": {
        "color": "hsl(301, 63%, 40%)"
    },
    ".language-javascript .token.template-string > .token.interpolation > .token.interpolation-punctuation.punctuation": {
        "color": "hsl(344, 84%, 43%)"
    },
    ".language-json .token.operator": {
        "color": "hsl(230, 8%, 24%)"
    },
    ".language-json .token.null.keyword": {
        "color": "hsl(35, 99%, 36%)"
    },
    ".language-markdown .token.url": {
        "color": "hsl(230, 8%, 24%)"
    },
    ".language-markdown .token.url > .token.operator": {
        "color": "hsl(230, 8%, 24%)"
    },
    ".language-markdown .token.url-reference.url > .token.string": {
        "color": "hsl(230, 8%, 24%)"
    },
    ".language-markdown .token.url > .token.content": {
        "color": "hsl(221, 87%, 60%)"
    },
    ".language-markdown .token.url > .token.url": {
        "color": "hsl(198, 99%, 37%)"
    },
    ".language-markdown .token.url-reference.url": {
        "color": "hsl(198, 99%, 37%)"
    },
    ".language-markdown .token.blockquote.punctuation": {
        "color": "hsl(230, 4%, 64%)",
        "fontStyle": "italic"
    },
    ".language-markdown .token.hr.punctuation": {
        "color": "hsl(230, 4%, 64%)",
        "fontStyle": "italic"
    },
    ".language-markdown .token.code-snippet": {
        "color": "hsl(119, 34%, 47%)"
    },
    ".language-markdown .token.bold .token.content": {
        "color": "hsl(35, 99%, 36%)"
    },
    ".language-markdown .token.italic .token.content": {
        "color": "hsl(301, 63%, 40%)"
    },
    ".language-markdown .token.strike .token.content": {
        "color": "hsl(5, 74%, 59%)"
    },
    ".language-markdown .token.strike .token.punctuation": {
        "color": "hsl(5, 74%, 59%)"
    },
    ".language-markdown .token.list.punctuation": {
        "color": "hsl(5, 74%, 59%)"
    },
    ".language-markdown .token.title.important > .token.punctuation": {
        "color": "hsl(5, 74%, 59%)"
    },
    "bold": {
        "fontWeight": "bold"
    },
    "italic": {
        "fontStyle": "italic"
    },
    "namespace": {
        "Opacity": "0.8"
    },
    "token.tab:not(:empty):before": {
        "color": "hsla(230, 8%, 24%, 0.2)"
    },
    "token.cr:before": {
        "color": "hsla(230, 8%, 24%, 0.2)"
    },
    "token.lf:before": {
        "color": "hsla(230, 8%, 24%, 0.2)"
    },
    "token.space:before": {
        "color": "hsla(230, 8%, 24%, 0.2)"
    },
    "div.code-toolbar > .toolbar.toolbar > .toolbar-item": {
        "marginRight": "0.4em"
    },
    "div.code-toolbar > .toolbar.toolbar > .toolbar-item > button": {
        "background": "hsl(230, 1%, 90%)",
        "color": "hsl(230, 6%, 44%)",
        "padding": "0.1em 0.4em",
        "borderRadius": "0.3em"
    },
    "div.code-toolbar > .toolbar.toolbar > .toolbar-item > a": {
        "background": "hsl(230, 1%, 90%)",
        "color": "hsl(230, 6%, 44%)",
        "padding": "0.1em 0.4em",
        "borderRadius": "0.3em"
    },
    "div.code-toolbar > .toolbar.toolbar > .toolbar-item > span": {
        "background": "hsl(230, 1%, 90%)",
        "color": "hsl(230, 6%, 44%)",
        "padding": "0.1em 0.4em",
        "borderRadius": "0.3em"
    },
    "div.code-toolbar > .toolbar.toolbar > .toolbar-item > button:hover": {
        "background": "hsl(230, 1%, 78%)",
        "color": "hsl(230, 8%, 24%)"
    },
    "div.code-toolbar > .toolbar.toolbar > .toolbar-item > button:focus": {
        "background": "hsl(230, 1%, 78%)",
        "color": "hsl(230, 8%, 24%)"
    },
    "div.code-toolbar > .toolbar.toolbar > .toolbar-item > a:hover": {
        "background": "hsl(230, 1%, 78%)",
        "color": "hsl(230, 8%, 24%)"
    },
    "div.code-toolbar > .toolbar.toolbar > .toolbar-item > a:focus": {
        "background": "hsl(230, 1%, 78%)",
        "color": "hsl(230, 8%, 24%)"
    },
    "div.code-toolbar > .toolbar.toolbar > .toolbar-item > span:hover": {
        "background": "hsl(230, 1%, 78%)",
        "color": "hsl(230, 8%, 24%)"
    },
    "div.code-toolbar > .toolbar.toolbar > .toolbar-item > span:focus": {
        "background": "hsl(230, 1%, 78%)",
        "color": "hsl(230, 8%, 24%)"
    },
    ".line-highlight.line-highlight": {
        "background": "hsla(230, 8%, 24%, 0.05)"
    },
    ".line-highlight.line-highlight:before": {
        "background": "hsl(230, 1%, 90%)",
        "color": "hsl(230, 8%, 24%)",
        "padding": "0.1em 0.6em",
        "borderRadius": "0.3em",
        "boxShadow": "0 2px 0 0 rgba(0, 0, 0, 0.2)"
    },
    ".line-highlight.line-highlight[data-end]:after": {
        "background": "hsl(230, 1%, 90%)",
        "color": "hsl(230, 8%, 24%)",
        "padding": "0.1em 0.6em",
        "borderRadius": "0.3em",
        "boxShadow": "0 2px 0 0 rgba(0, 0, 0, 0.2)"
    },
    "pre[id].linkable-line-numbers.linkable-line-numbers span.line-numbers-rows > span:hover:before": {
        "backgroundColor": "hsla(230, 8%, 24%, 0.05)"
    },
    ".line-numbers.line-numbers .line-numbers-rows": {
        "borderRightColor": "hsla(230, 8%, 24%, 0.2)"
    },
    ".command-line .command-line-prompt": {
        "borderRightColor": "hsla(230, 8%, 24%, 0.2)"
    },
    ".line-numbers .line-numbers-rows > span:before": {
        "color": "hsl(230, 1%, 62%)"
    },
    ".command-line .command-line-prompt > span:before": {
        "color": "hsl(230, 1%, 62%)"
    },
    ".rainbow-braces .token.token.punctuation.brace-level-1": {
        "color": "hsl(5, 74%, 59%)"
    },
    ".rainbow-braces .token.token.punctuation.brace-level-5": {
        "color": "hsl(5, 74%, 59%)"
    },
    ".rainbow-braces .token.token.punctuation.brace-level-9": {
        "color": "hsl(5, 74%, 59%)"
    },
    ".rainbow-braces .token.token.punctuation.brace-level-2": {
        "color": "hsl(119, 34%, 47%)"
    },
    ".rainbow-braces .token.token.punctuation.brace-level-6": {
        "color": "hsl(119, 34%, 47%)"
    },
    ".rainbow-braces .token.token.punctuation.brace-level-10": {
        "color": "hsl(119, 34%, 47%)"
    },
    ".rainbow-braces .token.token.punctuation.brace-level-3": {
        "color": "hsl(221, 87%, 60%)"
    },
    ".rainbow-braces .token.token.punctuation.brace-level-7": {
        "color": "hsl(221, 87%, 60%)"
    },
    ".rainbow-braces .token.token.punctuation.brace-level-11": {
        "color": "hsl(221, 87%, 60%)"
    },
    ".rainbow-braces .token.token.punctuation.brace-level-4": {
        "color": "hsl(301, 63%, 40%)"
    },
    ".rainbow-braces .token.token.punctuation.brace-level-8": {
        "color": "hsl(301, 63%, 40%)"
    },
    ".rainbow-braces .token.token.punctuation.brace-level-12": {
        "color": "hsl(301, 63%, 40%)"
    },
    "pre.diff-highlight > code .token.token.deleted:not(.prefix)": {
        "backgroundColor": "hsla(353, 100%, 66%, 0.15)"
    },
    "pre > code.diff-highlight .token.token.deleted:not(.prefix)": {
        "backgroundColor": "hsla(353, 100%, 66%, 0.15)"
    },
    "pre.diff-highlight > code .token.token.deleted:not(.prefix)::-moz-selection": {
        "backgroundColor": "hsla(353, 95%, 66%, 0.25)"
    },
    "pre.diff-highlight > code .token.token.deleted:not(.prefix) *::-moz-selection": {
        "backgroundColor": "hsla(353, 95%, 66%, 0.25)"
    },
    "pre > code.diff-highlight .token.token.deleted:not(.prefix)::-moz-selection": {
        "backgroundColor": "hsla(353, 95%, 66%, 0.25)"
    },
    "pre > code.diff-highlight .token.token.deleted:not(.prefix) *::-moz-selection": {
        "backgroundColor": "hsla(353, 95%, 66%, 0.25)"
    },
    "pre.diff-highlight > code .token.token.deleted:not(.prefix)::selection": {
        "backgroundColor": "hsla(353, 95%, 66%, 0.25)"
    },
    "pre.diff-highlight > code .token.token.deleted:not(.prefix) *::selection": {
        "backgroundColor": "hsla(353, 95%, 66%, 0.25)"
    },
    "pre > code.diff-highlight .token.token.deleted:not(.prefix)::selection": {
        "backgroundColor": "hsla(353, 95%, 66%, 0.25)"
    },
    "pre > code.diff-highlight .token.token.deleted:not(.prefix) *::selection": {
        "backgroundColor": "hsla(353, 95%, 66%, 0.25)"
    },
    "pre.diff-highlight > code .token.token.inserted:not(.prefix)": {
        "backgroundColor": "hsla(137, 100%, 55%, 0.15)"
    },
    "pre > code.diff-highlight .token.token.inserted:not(.prefix)": {
        "backgroundColor": "hsla(137, 100%, 55%, 0.15)"
    },
    "pre.diff-highlight > code .token.token.inserted:not(.prefix)::-moz-selection": {
        "backgroundColor": "hsla(135, 73%, 55%, 0.25)"
    },
    "pre.diff-highlight > code .token.token.inserted:not(.prefix) *::-moz-selection": {
        "backgroundColor": "hsla(135, 73%, 55%, 0.25)"
    },
    "pre > code.diff-highlight .token.token.inserted:not(.prefix)::-moz-selection": {
        "backgroundColor": "hsla(135, 73%, 55%, 0.25)"
    },
    "pre > code.diff-highlight .token.token.inserted:not(.prefix) *::-moz-selection": {
        "backgroundColor": "hsla(135, 73%, 55%, 0.25)"
    },
    "pre.diff-highlight > code .token.token.inserted:not(.prefix)::selection": {
        "backgroundColor": "hsla(135, 73%, 55%, 0.25)"
    },
    "pre.diff-highlight > code .token.token.inserted:not(.prefix) *::selection": {
        "backgroundColor": "hsla(135, 73%, 55%, 0.25)"
    },
    "pre > code.diff-highlight .token.token.inserted:not(.prefix)::selection": {
        "backgroundColor": "hsla(135, 73%, 55%, 0.25)"
    },
    "pre > code.diff-highlight .token.token.inserted:not(.prefix) *::selection": {
        "backgroundColor": "hsla(135, 73%, 55%, 0.25)"
    },
    ".prism-previewer.prism-previewer:before": {
        "borderColor": "hsl(0, 0, 95%)"
    },
    ".prism-previewer-gradient.prism-previewer-gradient div": {
        "borderColor": "hsl(0, 0, 95%)",
        "borderRadius": "0.3em"
    },
    ".prism-previewer-color.prism-previewer-color:before": {
        "borderRadius": "0.3em"
    },
    ".prism-previewer-easing.prism-previewer-easing:before": {
        "borderRadius": "0.3em"
    },
    ".prism-previewer.prism-previewer:after": {
        "borderTopColor": "hsl(0, 0, 95%)"
    },
    ".prism-previewer-flipped.prism-previewer-flipped.after": {
        "borderBottomColor": "hsl(0, 0, 95%)"
    },
    ".prism-previewer-angle.prism-previewer-angle:before": {
        "background": "hsl(0, 0%, 100%)"
    },
    ".prism-previewer-time.prism-previewer-time:before": {
        "background": "hsl(0, 0%, 100%)"
    },
    ".prism-previewer-easing.prism-previewer-easing": {
        "background": "hsl(0, 0%, 100%)"
    },
    ".prism-previewer-angle.prism-previewer-angle circle": {
        "stroke": "hsl(230, 8%, 24%)",
        "strokeOpacity": "1"
    },
    ".prism-previewer-time.prism-previewer-time circle": {
        "stroke": "hsl(230, 8%, 24%)",
        "strokeOpacity": "1"
    },
    ".prism-previewer-easing.prism-previewer-easing circle": {
        "stroke": "hsl(230, 8%, 24%)",
        "fill": "transparent"
    },
    ".prism-previewer-easing.prism-previewer-easing path": {
        "stroke": "hsl(230, 8%, 24%)"
    },
    ".prism-previewer-easing.prism-previewer-easing line": {
        "stroke": "hsl(230, 8%, 24%)"
    }
}